import jQuery from 'jquery';
import Datepicker from 'vanillajs-datepicker/Datepicker';

window.$=jQuery;

jQuery(function($) {
  
  $(".date-field").each(function() {
    var datepicker = new Datepicker(this, {
      format: "d.m.yyyy.",
    }); 
  });
  
});
