import jQuery from 'jquery';

window.$=jQuery;

jQuery(function($) {
  $(".news-outer").each(function() {
    var $outer=$(this);
    var $list=$outer.find(".news-container>ul");
    var $els=$list.find(">li");
    var $navProg=$outer.find(".prog");
    var $navPrev=$outer.find(".prev");
    var $navNext=$outer.find(".next");
    
    var current=0;
    var updateCurrent=function(newCurrent, animate) {
      current=newCurrent;
      if (animate=='skip') {
        $navProg.html((current+1)+"/"+$els.length);
        boxNav.find("a").removeClass("current").eq(current).addClass("current");
        return;
      }
      var currentOffsetLeft=$els[current].offsetLeft;
      if (animate) {
        $list.addClass("anim");
        $list.animate({scrollLeft: currentOffsetLeft}, 300, function() {
          $list.removeClass("anim");
        });
      } else {
        $list.scrollLeft(currentOffsetLeft);
      }
    };
    updateCurrent(0);
    
    $navPrev.click(function(e) {
      updateCurrent((current+$els.length-1)%$els.length, true);
      e.preventDefault();
    });
    $navNext.click(function(e) {
      updateCurrent((current+1)%$els.length, true);
      e.preventDefault();
    });
    
    var boxNav=$("<div />", {class: "box-nav"});
    $outer.find(".news-nav").after(boxNav);
    for(let i=0;i<$els.length;i++) {
      var boxLink=$("<a />", {href: ""}).click(function(e) {
        e.preventDefault();
        updateCurrent(boxNav.find("a").index(this), true);
      }).appendTo(boxNav);
    }
    
    $list.scroll(function() {
      var current=0;
      var scrollLeft=$list[0].scrollLeft;
      var minDiff=100000;
      $els.each(function(index) {
        var diff=Math.abs(this.offsetLeft-scrollLeft);
        if (diff<minDiff) {
          minDiff=diff;
          current=index;
        }
      });
      updateCurrent(current, "skip");
    }).scroll();
    if (window.innerWidth>990)
      $els.eq(0).clone(true).appendTo($els.parent());
  });
});


jQuery(function($) {
  $(".select").click(function(ev) {
    ev.preventDefault();
    $(this).toggleClass("open");
  });
});


jQuery(function($) {
  $(".mob-handle").click(function(e) {
    e.preventDefault();
    $(this).closest("header").toggleClass("open");
  });
  $(".announcements .box-list, .tiles .tiles-list, .partner-links .plinks-list").each(function() {
    var container=$(this);
    var boxNav=$("<div />", {class: "box-nav"});
    $(this).after(boxNav);
    var lis=$(this).find("li");
    for(let i=0;i<lis.length;i++) {
      var boxLink=$("<a />", {href: ""}).click(function(e) {
        e.preventDefault();
        var offset=lis[i].offsetLeft;
        container.addClass("animating");
        container.animate({scrollLeft: offset}, function() {
          container.removeClass("animating");
        });
      }).appendTo(boxNav);
    }
    var currentIndex=-1;
    container.on("scroll", function(e) {
      var left=this.scrollLeft;
      var minDistance=100000;
      var minIndex=-1;
      for(var i=0;i<lis.length;i++) {
        if (Math.abs(lis[i].offsetLeft-left)<minDistance) {
          minDistance=Math.abs(lis[i].offsetLeft-left);
          minIndex=i;
        }
      }
      if (minIndex!=-1 && minIndex!=currentIndex) {
        currentIndex=minIndex;
        boxNav.find("a").removeClass("current").eq(currentIndex).addClass("current");
      }
    }).trigger("scroll");
  });
  
  $(".side-nav a").click(function(e) {
    if (window.innerWidth>990)
      return;
    if ($(this).closest(".side-nav").hasClass("open"))
      return;
    e.preventDefault();
    $(this).closest(".side-nav").addClass("open");
    $("html,body").scrollTop(40);
  });
  
  $(".pg-tools").each(function() {
    $(this).clone(true).insertAfter(".split-content aside");
  });
  
  
  $("header nav li:has(ul)>a").click(function(e) {
    if (window.innerWidth>990)
      return;
    
    var li=$(this).closest("li");
    if (li.hasClass("open"))
      return;
    var ul=$(this).closest("ul");
    ul.find(">li.open").removeClass("open").find(">ul").slideUp(300);
    li.find(">ul").slideDown(300);
    e.preventDefault();
    li.toggleClass("open");
  });
  
  $(".totop a").click(function(e) {
    e.preventDefault();
    $("html,body").animate({scrollTop: 0});
  });
  
});


jQuery(function($) {
  var classEls=[
    {el: $("header"), offset: 30, className: "scrolled" },
  ];
  var stickyEls=[
    {el: $(".split-content aside .side-nav"), offset: 40, className: "scrolled" },
  ];
  var animElsSrc=[
    {el: $("header .logo"), offset: 0, duration: .2, cduration: .5 },
    {el: $("header .head-top"), offset: 0, duration: .2, cduration: .5 },
    {el: $("header .navi"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".intro-img"), offset: 0, duration: .2, cduration: .3 },
    {el: $(".section-title"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".pg-tools>.container"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-content aside"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>h1"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>form"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>.docprop"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>.text-content>*"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>.text-content>.constitution>*"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>.results-header"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>.results"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>p"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>ul>li"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".split-main>.pager"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".box-list li"), offset: 0, duration: .2, cduration: .3 },
    {el: $(".news-nav, .box-nav"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".news-container li:nth-child(1), .news-container li:nth-child(2)"), offset: 0, duration: .2, cduration: .3 },
    {el: $(".tiles-list li"), offset: 0, duration: .2, cduration: .3 },
    {el: $(".plinks-list li"), offset: 0, duration: .2, cduration: .3 },
    {el: $(".foot-contact"), offset: 0, duration: .2, cduration: .3 },
    {el: $(".fn-split>*"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".foot-copy"), offset: 0, duration: .2, cduration: .5 },
    {el: $(".footnote>.container>*"), offset: 0, duration: .2, cduration: .5 },
//     {el: $(""), offset: 0, duration: .2, cduration: .5 },
  ];
  var animEls=[];
  for(var item of animElsSrc) {
    for(var i=0;i<item.el.length;i++)
      animEls.push({...item, el: item.el.eq(i)});
  }
  var animQueue=[];
  var addToAnimQueue=function(el) {
//     console.log("Animating", el);
    animQueue.push(el);
    if (animQueue.length==1)
      animNextEl();
  }
  var animNextEl=function() {
    if (!animQueue.length)
      return;
    var nextEl=animQueue[0];
    if (nextEl) {
      nextEl.el.addClass("animating");
      setTimeout(()=>{
        nextEl.el.removeClass("animating").addClass("animated");
      }, (nextEl.cduration || nextEl.duration)*1000);
    }
    setTimeout(()=>{
      animQueue.shift();
      animNextEl();
    }, nextEl.el.offset().top<window.scrollY?1:nextEl.duration*1000);
  }
  $(window).on("scroll.anims", function() {
    var scrollTop=window.scrollY;
    var windowHeight=window.innerHeight;
    for(var item of classEls) {
      var isItemScrolled=(item.offset || 0)-scrollTop<0;
      if (item.scrolled && !isItemScrolled)
        item.el.removeClass(item.className);
      if (!item.scrolled && isItemScrolled)
        item.el.addClass(item.className);
      item.scrolled=isItemScrolled;
    }
    if (window.innerWidth<=760) {
      for(var item of stickyEls) {
        var isItemScrolled=(item.offset || 0)-scrollTop<0;
        if (item.scrolled && !isItemScrolled)
          item.el.removeClass(item.className);
        if (!item.scrolled && isItemScrolled)
          item.el.addClass(item.className);
        item.scrolled=isItemScrolled;
        var offsetTop=isItemScrolled?85:0;
        item.el.css("top", offsetTop);
      }
    }
    for(var item of animEls) {
      var isItemVisible=item.el.offset().top+(item.offset || 0)-windowHeight-scrollTop<0;
      if (item.animated)
        continue;
      if (isItemVisible)
        addToAnimQueue(item);
      item.animated=isItemVisible;
    }
    
  }).trigger("scroll.anims");
  
  
  $("table.results tr:not(.details)").click(function() {
    if ($(this).hasClass("open")) {
      $(this).removeClass("open").next().find("td ul").slideUp(300, function() {
        $(this).parent().hide();
      });
    } else {
      $("table.results tr.open").removeClass("open").next().find("td ul").slideUp(300, function() {
        $(this).parent().hide();
      });
      $(this).addClass("open").next().find("td").show().find("ul").slideDown(300);
    }
  });
});
